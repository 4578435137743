import React, { FormEvent, useState } from 'react'
import { Block, Button, Content, Flex, FlexSpacer, Input } from 'gerami'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { FaArrowRight } from 'react-icons/fa'

type EmailRegistrationProps = {}

const EmailRegistration: React.FC<EmailRegistrationProps> = ({}) => {
  const [isSending, setIsSending] = useState(false)
  const [responseMessage, setResponseMessage] = useState<string>()

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    setIsSending(true)

    addToMailchimp((e.target as any).EMAIL.value)
      .then((data: any) =>
        setResponseMessage(data?.msg || `Sorry, some error has occurred.`)
      )
      .catch(() =>
        // returns a 200 status code, if network connection succeeds
        setResponseMessage(
          `Sorry, some error has occurred.<br/>This could possibly be a network connection issue.`
        )
      )
      .finally(() => setIsSending(false))
  }

  return (
    <div
      className="padding-vertical-very-big padding-left-big"
      style={{
        backgroundImage: `linear-gradient(to top, transparent, rgba(220,220,220,0.42))`
      }}
    >
      <div className="padding-vertical-very-big margin-bottom-very-big">
        <Content
          transparent
          size="M"
          className="margin-vertical-very-big"
          style={{ borderLeft: `solid rgba(0,0,0,0.14) 4px` }}
        >
          <Block className="padding-vertical-none">
            <h1 className="padding-top-none">
              Subscribe
              <small>
                <FaArrowRight className="margin-left-big" />
              </small>
            </h1>
            <div className="subtitle margin-bottom-very-big">
              Register your email with us to receive updates.
            </div>

            {responseMessage ? (
              <div dangerouslySetInnerHTML={{ __html: responseMessage }} />
            ) : (
              <form
                onSubmit={handleSubmit}
                className="margin-vertical-very-big"
              >
                <Flex>
                  <FlexSpacer />

                  <Input
                    type="email"
                    placeholder="Your Email"
                    name="EMAIL"
                    className="full-width"
                    disabled={isSending}
                    required
                  />

                  <div className="padding-horizontal-small" />

                  <Button type="submit" disabled={isSending}>
                    Register
                  </Button>

                  <FlexSpacer />
                </Flex>
              </form>
            )}
          </Block>
        </Content>
      </div>
    </div>
  )
}

export default EmailRegistration
