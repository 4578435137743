export enum NavCategory {
  PRIMARY,
  FOREIGN,
  SECONDARY
}

export type NavType = {
  name: string
  url: string
  category: NavCategory
}

export const navData: NavType[] = [
  { category: NavCategory.PRIMARY, name: `Home`, url: `/` },
  { category: NavCategory.PRIMARY, name: `Blog`, url: `/blog` },
  { category: NavCategory.PRIMARY, name: `Podcasts`, url: `/podcasts` },
  { category: NavCategory.PRIMARY, name: `Videos`, url: `/videos` },
  { category: NavCategory.PRIMARY, name: `About Us`, url: `/about` },

  {
    category: NavCategory.FOREIGN,
    name: `Shop`,
    url: `https://shop.zetseat.church/`
  },
  {
    category: NavCategory.FOREIGN,
    name: `Live TV`,
    url: `https://live.zetseat.church/`
  },

  { category: NavCategory.SECONDARY, name: `Schedules`, url: `/schedules` },
  { category: NavCategory.SECONDARY, name: `Youth`, url: `/youth` },
  { category: NavCategory.SECONDARY, name: `Kids`, url: `/kids` }
]
