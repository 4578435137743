import React, { useMemo, useState } from 'react'
import { Block, Button, Content, Flex, FlexSpacer } from 'gerami'
import { MdClose } from 'react-icons/md'

import './nav.scss'
import { usePage } from '../../../../contexts/page-context/page-context'
import { NavCategory, navData, NavType } from './nav-data'
import useSiteMetadata from '../../../../hooks/use-site-metadata/use-site-metadata'
import Anchor from '../../../anchor/anchor'

type NavProps = {}

const Nav: React.FC<NavProps> = ({}) => {
  const page = usePage()

  const props = useMemo(
    () => ({
      activePathname: page?.location.pathname || ``,
      primaries: navData.filter(nav => nav.category === NavCategory.PRIMARY),
      foreigners: navData.filter(nav => nav.category === NavCategory.FOREIGN)
    }),
    [page]
  )

  return (
    <nav className="margin-auto nav">
      <div className="nav-l-container">
        <Nav_L {...props} />
      </div>
      <div className="nav-s-container">
        <Nav_S {...props} />
      </div>
    </nav>
  )
}

//
// Nav_L:
//

type Nav_LProps = {
  activePathname: string
  primaries: NavType[]
  foreigners: NavType[]
}

const Nav_L: React.FC<Nav_LProps> = ({
  activePathname,
  primaries,
  foreigners
}) => {
  return (
    <div className="nav-l">
      {primaries.map((nav, i) => (
        <Anchor
          key={i}
          className={`nav-l-link${
            activePathname.replace(/\/$/, '') === nav.url.replace(/\/$/, '')
              ? ' nav-l-link-active'
              : ''
          }`}
          to={nav.url}
        >
          {nav.name}
        </Anchor>
      ))}

      {primaries.length && foreigners.length ? (
        <div className="nav-l-separator">|</div>
      ) : null}

      {foreigners.map((nav, i) => (
        <Anchor
          key={i}
          className={`nav-l-link${
            activePathname.replace(/\/$/, '') === nav.url.replace(/\/$/, '')
              ? ' nav-l-link-active'
              : ''
          }`}
          to={nav.url}
          target="_blank"
          rel="noopener nofollow"
        >
          {nav.name}
        </Anchor>
      ))}
    </div>
  )
}

//
// Nav_S:
//

type Nav_SProps = {
  activePathname: string
  primaries: NavType[]
  foreigners: NavType[]
}

export const Nav_S: React.FC<Nav_SProps> = ({
  activePathname,
  primaries,
  foreigners
}) => {
  const [open, setOpen] = useState(false)

  const siteMetadata = useSiteMetadata()

  return (
    <>
      <div className="nav-s">
        <Button className="nav-s-toggle" onClick={() => setOpen(!open)}>
          Menu
        </Button>
      </div>

      {!open ? null : (
        <div className="nav-s-underlay">
          <div
            className="nav-s-overlay-closer"
            onClick={() => setOpen(false)}
          />

          <Content size="S" transparent className="nav-s-overlay-content">
            <Block>
              <Flex>
                <h6 className="padding-top-big">{siteMetadata.title}</h6>

                <FlexSpacer />

                <Button
                  className="nav-s-close-button"
                  onClick={() => setOpen(false)}
                >
                  <MdClose />
                </Button>
              </Flex>

              <hr className="nav-s-separator" />
            </Block>

            <Block first={false} last={!foreigners.length}>
              {primaries.map((nav, i) => (
                <Anchor
                  key={i}
                  className={`nav-s-link${
                    activePathname.replace(/\/$/, '') ===
                    nav.url.replace(/\/$/, '')
                      ? ' nav-l-link-active'
                      : ''
                  }`}
                  to={nav.url}
                >
                  {nav.name}
                </Anchor>
              ))}
            </Block>

            {primaries.length && foreigners.length ? (
              <Block>
                <hr className="nav-s-separator" />
              </Block>
            ) : null}

            <Block first={!primaries.length} last={!!foreigners.length}>
              {foreigners.map((nav, i) => (
                <Anchor
                  key={i}
                  className={`nav-s-link${
                    activePathname.replace(/\/$/, '') ===
                    nav.url.replace(/\/$/, '')
                      ? ' nav-l-link-active'
                      : ''
                  }`}
                  to={nav.url}
                  target="_blank"
                  rel="noopener nofollow"
                >
                  {nav.name}
                </Anchor>
              ))}
            </Block>
          </Content>
        </div>
      )}
    </>
  )
}

export default Nav
