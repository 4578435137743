import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import { Content, Flex } from 'gerami'

import './pre-header.scss'
import { PreHeaderQuery } from '../../../../../../graphql-types'
import { useIsNew } from '../../../../contexts/is-new-context/is-new-context'

type PreHeaderProps = {}

const PreHeader: React.FC<PreHeaderProps> = ({}) => {
  const data = useStaticQuery<PreHeaderQuery>(query)

  const isNew = useIsNew()

  return !isNew ? null : (
    <div className="pre-header">
      <div className="pre-header-bg padding-big">
        <Content
          transparent
          size="XXL"
          className="padding-vertical-very-big center"
        >
          <Flex>
            <GatsbyImage
              className="pre-header-logo margin-auto"
              fixed={data.logo?.childImageSharp?.fixed as any}
            />
            <div className="margin-auto">
              <h1 className="left">Zetseat Church</h1>
              <h3 className="left subtitle light padding-top-normal">
                {data.generalInfo?.frontmatter?.motto}
              </h3>
            </div>
          </Flex>
        </Content>
      </div>
    </div>
  )
}

export default PreHeader

export const query = graphql`
  query PreHeader {
    logo: file(relativePath: { eq: "images/shared/logo.png" }) {
      childImageSharp {
        fixed(height: 140) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    generalInfo: markdownRemark(fields: { slug: { regex: "/info/general/" } }) {
      frontmatter {
        motto
      }
    }
  }
`
