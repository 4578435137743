import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Flex, FlexSpacer } from 'gerami'
import GatsbyImage from 'gatsby-image'

import './header.scss'
import { HeaderQuery } from '../../../../../../graphql-types'
import Nav from '../nav/nav'
import Anchor from '../../../anchor/anchor'

type HeaderProps = {}

const Header: React.FC<HeaderProps> = ({}) => {
  const data = useStaticQuery<HeaderQuery>(query)

  return (
    <header className="header">
      <div className="header-space">
        <Flex>
          <Anchor to="/" className="margin-auto">
            <Flex>
              <GatsbyImage
                className="header-logo"
                fixed={data.file?.childImageSharp?.fixed as any}
              />
              <h1 className="header-title">
                Zetseat <span>Apostolic</span>
                <br />
                <span>Reformation</span> Church
              </h1>
            </Flex>
          </Anchor>

          <FlexSpacer />

          <Nav />
        </Flex>
      </div>
    </header>
  )
}

export default Header

export const query = graphql`
  query Header {
    file(relativePath: { eq: "images/shared/logo.png" }) {
      childImageSharp {
        fixed(height: 56) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  }
`
